import Header from "../components/header";
import tatoo1 from "../images/tatoo1.jpg";
import tatoo2 from "../images/tatoo2.jpg";
import tatoo3 from "../images/tatoo3.jpg";
import tatoo4 from "../images/tatoo4.jpg";
import tatoo5 from "../images/tatoo5.png";
import video from "../images/tato-vid.gif";
import Footer from "../components/footer";
import TatooSlider from "../components/tatoo-slider";
import '../index.css';
import { useTranslation } from 'react-i18next';
import { useState, useEffect } from "react";
import Loading from "./Loading";

const Tatoo = () => {

    const { t } = useTranslation();

    const [imagesLoaded, setImagesLoaded] = useState(false);

    useEffect(() => {
      const images = [tatoo1, tatoo2, tatoo3, tatoo4, tatoo5, video];
      let loadedCount = 0;
    
      const handleImageLoad = () => {
        loadedCount++;
        if (loadedCount === images.length) {
          setImagesLoaded(true);
        }

      };
    
      images.forEach(imageSrc => {
        const img = new Image();
        img.onload = handleImageLoad;
        img.src = imageSrc;
      });

    }, []); 
    
    if (!imagesLoaded) {
      return <Loading />;
    }
    return (
        <>
        <div className="main-header">
          <Header/>
        </div>
        <div className="flex items-center justify-center mt-[100px] sm:w-full">
        <div className="z-20 lg:text-8xl sm:text-5xl sm:mr-[-150px] sm:ml-10 front-page">
        <p className="text-[#e9fc53] ">{t("ink")}</p>
            <p>{t("city")}</p>
            <p>{t("stand")}</p>
        </div>
        <div>
        <div>
                <img src={video} className="lg:w-[700px] sm:w-[300px] sm:ml-[-20px] h-[500px] object-none"/>
                <div className="lg:float-right lg:mt-[-450px] sm:mt-[-470px] lg:mr-[-150px] h-[500px] lg:w-[500px] sm:mr-10 sm:ml-[150px] sm:w-[200px] bg-[#aaa]">
            </div>
        </div>
       
        </div>
        </div>
        <div className="flex md:flex-wrap lg:flex-row sm:flex-col lg:justify-center mt-[100px]">
    <div className="relative max-w-[400px] min-w-[300px] sm:m-4 lg:m-4">
        <div className="relative w-full h-[400px]">
            <img src={tatoo4} className="w-full h-full object-cover" />
        </div>
        <div className="flex flex-col justify-center items-center bg-white">
            <p className="text-black pt-[10px]">Ilja Petrenko</p>
            <div className="h-[50px] mt-[20px] cursor-pointer">
                <a href='https://instagram.com/iljatattoo?igshid=MXQ2dGo0MXlvd2d0NA==' className="text-black bg-[#e9fc53] border-0 px-10 py-5 no-underline cursor-pointer">
                    {t('book_now')}
                </a>
            </div>
        </div>
    </div>

    <div className="relative max-w-[400px] min-w-[300px] sm:m-4 lg:m-4">
        <div className="relative w-full h-[400px]">
            <img src={tatoo1} className="w-full h-full object-cover" />
        </div>
        <div className="flex flex-col justify-center items-center bg-white">
            <p className="text-black pt-[10px]">Daniil Pihla</p>
            <div className="h-[50px] mt-[20px] cursor-pointer">
                <a href='https://instagram.com/danieltattootln?igshid=ZmU4ZWh4YWkxM2dp' className="text-black bg-[#e9fc53] border-0 px-10 py-5 no-underline cursor-pointer">
                    {t('book_now')}
                </a>
            </div>
        </div>
    </div>

    <div className="relative max-w-[400px] min-w-[300px] sm:m-4 lg:m-4">
        <div className="relative w-full h-[400px]">
            <img src={tatoo3} className="w-full h-full object-cover" />
        </div>
        <div className="flex flex-col justify-center items-center bg-white">
            <p className="text-black pt-[10px]">Roman Warwink</p>
            <div className="h-[50px] mt-[20px] cursor-pointer">
                <a href='https://instagram.com/romanwarwink?igshid=MWc3Mnh0aXNjbDk4Nw==' className="text-black bg-[#e9fc53] border-0 px-10 py-5 no-underline cursor-pointer">
                    {t('book_now')}
                </a>
            </div>
        </div>
    </div>

    <div className="relative max-w-[400px] min-w-[300px] sm:m-4 lg:m-4">
        <div className="relative w-full h-[400px]">
            <img src={tatoo5} className="w-full h-full object-cover" />
        </div>
        <div className="flex flex-col justify-center items-center bg-white">
            <p className="text-black pt-[10px]">Sophie Jelly</p>
            <div className="h-[50px] mt-[20px] cursor-pointer">
                <a href='https://instagram.com/_sofione_?igshid=MTIybXJwdGlodjNzeA==' className="text-black bg-[#e9fc53] border-0 px-10 py-5 no-underline cursor-pointer">
                    {t('book_now')}
                </a>
            </div>
        </div>
    </div>


            </div>

            <div className="flex sm:flex-col lg:flex-row lg:justify-around lg:mx-0 text-white mt-[150px] lg:mb-[300px] sm:mx-10 front-page">
                <div className="text-8xl lg:mb-0 sm:mb-20">
                    <h1 className="sticky">{t("faq")}</h1>
                </div>
                <div>
                    <div className="mb-10">
                        <h1 className="text-6xl mb-4">
                        {t("book")}
                        </h1>
                        <div className="text-[#b8b8b8] text-xl">
                            <p>
                            {t("bookA.0")}<a href="#" className="text-[#e9fc53]">{t('bookA.1')}</a>{t("bookA.2")}
                            </p>
                            <p>{t("bookB")}</p>
                            <p>{t("bookC")}
                            </p>
                        </div>
                    </div>
                    <div className="mb-10">
                        <h1 className="text-6xl mb-4">
                        {t("many")}
                        </h1>
                        <div className="text-[#b8b8b8] text-xl">
                        <p>{t("manyA")}</p>
                        <p>{t("manyB")}</p>
                        </div>
                  
                    </div>
                    <div className="mb-10">
                        <h1 className="text-6xl mb-4">
                        {t("walk")}
                        </h1>
                        <p className="text-[#b8b8b8] text-xl">
                        {t("walkA")}
                        </p>
                    </div>
                    <div className="mb-10">
                        <h1 className="text-6xl mb-4">
                        {t("care")}
                        </h1>
                        <p className="text-[#b8b8b8] text-xl">
                        {t("careA")}
                        </p>
                    </div>
                </div>
            </div>
            <TatooSlider/>
            <Footer/>
        </>
    )
}

export default Tatoo;