import firstPhoto from "../images/ink.png";
import frontOne from "../images/front-1.jpeg";
import '../index.css';
import { useTranslation } from 'react-i18next';


const Main = () => {
    const { t } = useTranslation();
 
    return (
        <div className="relative h-[100vh]">
            <div className="lg:w-100 my-[-60px]">
            <img src={frontOne} className="z-10 lg:m-100 lg:w-[700px] lg:h-[500px] transform -scale-x-100 " alt="Rempire" />
            </div>
            <div className="z-20">
                <img src={firstPhoto} className="relative float-right lg:block sm:hidden lg:mt-[-300px] lg:w-[1100px]" alt="Rempire" />
            </div>
            <div className="relative z-30 lg:h-[450px] sm:h-auto sm:pb-20 sm:w-[80%] lg:w-[750px] bg-white lg:mx-[300px] lg:mt-[-300px] sm:mt-[-200px] sm:mx-auto  text-black front-page">
                <div className="p-10 lg:whitespace-nowrap">
                    <p className="lg:text-[60px] lg:mt-0 sm:mt-[-15px] sm:text-[40px]">{t("first-main")}</p>
                    <p className="lg:text-[60px] mt-[-15px] sm:text-[40px]">{t("second-main")}</p>
                    <p className="lg:text-[60px] mt-[-15px] sm:text-[40px]">{t("third-main")}</p>
                </div>
                <div className="lg:absolute flex lg:justify-around lg:flex-row lg:items-baseline sm:flex-col sm:justify-center sm:items-center lg:mt-[30px] buttons lg:w-full">
                <a href='https://www.rempire.ee/barbershop' className=" border-0 no-underline cursor-pointer">
                    <div className="h-[50px] lg:w-[200px] sm:w-[250px] text-black bg-[#e9fc53] sm:text-center sm:pt-[15px] sm:mt-[10px]">
                    {t("barbers")}
                    </div>
                    </a>
                    <a href='https://www.rempire.ee/tattoo' className="cursor-pointer">
                    <div className="h-[50px] lg:w-[200px] sm:w-[250px] text-black border border-black sm:text-center sm:pt-[15px] sm:mt-[20px]">
                    {t("tattoo")}
                    </div>
                    </a>
                    <a target="_blank" href='https://rempireshop.com/' className="cursor-pointer" rel="noreferrer">
                    <div className="h-[50px] lg:w-[200px] sm:w-[250px] text-black bg-[#e9fc53] sm:text-center sm:pt-[15px] sm:mt-[20px]">
                    {t("shop")}
                    </div>
                    </a>
                 
                </div>
            </div>

        </div>
    )
}

export default Main;
